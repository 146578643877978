/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/css/AddNewItem.module.css";
import { add_new_job, get_domains, get_product_list, getStoreData, } from '../../../Utils/method'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import ukbd from '../../../assets/UKBD.png';

function AddNewJob() {
    let navigate = useNavigate();
    const store_data = getStoreData({ type: 'get' });
    const [loader, setLoader] = useState();
    const [all_product_list, setAll_Product_list] = useState([]);
    const [product_list, setProduct_list] = useState([]);
    const [domain_list, setDomain_list] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [activeTab, setActiveTab] = useState(1)
    const selectTab = (e) => { setActiveTab(e) }

    const [form_data, set_form_data] = useState({
        name: '',
        short_details: '',
        details: '',
        note_for_item: '',
        is_visible_in_store: true,
        product_id: '',
        salary_time: '',
        salary_range_start: '',
        salary_range_end: '',
        location: '',
        deadline: '',
        job_id: '',
        job_type: '',
        domain_id: '',
        vacancy: '1',
        product_sequence_number: ''
    })

    const reset_form = () => {
        set_form_data({
            name: '',
            short_details: '',
            details: '',
            note_for_item: '',
            is_visible_in_store: true,
            product_id: '',
            salary_time: '',
            salary_range_start: '',
            salary_range_end: '',
            location: '',
            deadline: '',
            job_id: '',
            job_type: '',
            domain_id: '',
            vacancy: 1,
            product_sequence_number: ''
        })
        console.log('Reset called');
        setSelectedProduct(null)
    }

    const setNoteContent = (e) => {
        console.log(183, e);
        set_form_data({ ...form_data, note_for_item: e });
    }

    const setShortDetailsContent = (e) => {
        console.log(183, e);
        set_form_data({ ...form_data, short_details: e });
    }

    const setDetailsContent = (e) => {
        console.log(183, e);
        set_form_data({ ...form_data, details: e });
    }

    const handleOnChange = (e, ext_name) => {
        if (ext_name) {
            console.log(e, ext_name);
            if (ext_name === 'product_id') {
                let data = all_product_list.filter(i => i._id === e.value)
                console.log(411, data);
                set_form_data({
                    ...form_data,
                    [ext_name]: e.value,
                    product_sequence_number: data[0].sequence_number,
                });
                setSelectedProduct(e)
            } else if (ext_name === 'job_type') {
                set_form_data({
                    ...form_data,
                    [ext_name]: e.value,
                });
            } else if (ext_name === 'salary_time') {
                set_form_data({
                    ...form_data,
                    [ext_name]: e.value,
                });
            }
        } else {
            const { name, value } = e.target;
            // console.log(name, value);
            if (name === 'is_visible_in_store') {
                set_form_data({ ...form_data, [name]: e.target.checked });
            } else if (name === 'store_id') {
                set_form_data({
                    ...form_data,
                    [name]: value,
                });
            } else {
                set_form_data({ ...form_data, [name]: value });
            }
        }
    }

    function findEmptyKeys(obj) {
        const emptyKeys = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];

                // Check if the value is considered empty
                if (value === '') {
                    if (key !== 'short_details' && key !== 'note_for_item' && key !== 'salary_range_end') {
                        emptyKeys.push(key);
                    }
                }
            }
        }

        return emptyKeys;
    }

    const saveNewItem = async () => {
        const emptyKeys = findEmptyKeys(form_data);
        if (emptyKeys.length > 0) {
            console.log("Empty keys found:", emptyKeys);
            toast.error(emptyKeys.join(',') + ' fields cannot be empty', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return
        }
        if (form_data.name.trim() === '') {
            toast.error('Please enter Item Name', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return
        } else if (form_data.product_id === '') {
            toast.error('Please select Sub-category', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return
        }

        setLoader(true)
        const data = new FormData();

        data.append('domain_id', form_data.domain_id);
        data.append('name[0]', form_data.name);
        data.append('short_details[0]', form_data.short_details);
        data.append('details[0]', form_data.details);
        data.append('product_sequence_number', form_data.product_sequence_number);
        data.append('note_for_item', form_data.note_for_item);
        data.append('is_visible_in_store', form_data.is_visible_in_store);
        data.append('product_id', form_data.product_id);
        data.append('salary_time', form_data.salary_time);
        data.append('salary_range_start', form_data.salary_range_start);
        data.append('salary_range_end', form_data.salary_range_end);
        data.append('location', form_data.location);
        data.append('deadline', form_data.deadline);
        data.append('job_id', form_data.job_id);
        data.append('job_type', form_data.job_type);
        // data.append('domain_ids', form_data.domain_ids);
        data.append('vacancy', form_data.vacancy);
        data.append('store_id', store_data.store._id);
        data.append('server_token', store_data.store.server_token);
        data.append('timezone', store_data.timezone);
        try {
            let apires = await add_new_job(data);
            console.log(126, apires)
            if (apires.success) {
                // update_image(apires.item)
                reset_form();
                toast.success('Job added successfully!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            } else {
                toast.error(apires.status_phrase, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const [selected_domain, setselected_domain] = useState(null)
    const getProduct_list = async () => {
        try {
            let apires = await get_product_list({
                store_id: store_data.store._id,
                server_token: store_data.store.server_token,
                domain_id: JSON.parse(localStorage.getItem('domain'))._id
            });

            console.log('get_product_list:', apires);

            if (apires.success) {
                let data = []
                let sort_v = apires.products.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                for (const p of sort_v) {
                    data.push({ value: p._id, label: p.name[0], domain_ids: p.domain_ids })
                }
                setProduct_list(data)
                setAll_Product_list(sort_v);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (selected_domain) {
            let data = []
            for (const p of all_product_list) {
                if (p.domain_ids.indexOf(selected_domain.value) > -1) {
                    data.push({ value: p._id, label: p.name[0], domain_ids: p.domain_ids })
                }
            }
            setProduct_list(data)
        }

    }, [selected_domain])


    const get_domain_list = async () => {
        try {
            let apires = await get_domains();
            let data = []
            let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
            for (const p of sort_v) {
                data.push({ value: p._id, label: p.name })
            }
            console.log('get_domains:', data);
            setDomain_list(data)
        } catch (error) {
            console.log();
        }
    }
    const handleDomain = (d) => {
        // console.log('domaindata', d);
        // let data = []
        // for (const iterator of d) {
        //     console.log(iterator.value);
        //     data.push(iterator.value)
        // }
        setselected_domain(d)
        set_form_data({ ...form_data, domain_id: d.value })
    }

    useEffect(() => {
        get_domain_list()
        getProduct_list()
    }, [])

    useEffect(() => {
        console.log(form_data)
    }, [form_data])

    const convertToPoint = (e) => {
        const { name, value } = e.target;
        set_form_data({ ...form_data, [name]: Number(value).toFixed(2) });
    }

    const selectStyles = {
        control: (base) => ({
            ...base,
            fontSize: '14px',
            boxShadow: 'none',
            // '&:focus': {
            //     border: '0 !important',
            // },
            background: '#fff',
            minHeight: '18px',
            border: '1px solid #ced4da',
            borderRadius: '2px',
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: 'rgba(0,48,25,.1)',
        }),
        options: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 0,
            padding: '1px 15px',
            width: '100%',
            borderBottom: '1px solid rgba(0,48,25,1)'
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 0,
            height: 'auto',
            width: '100%',
            right: '0px',
            top: '13px',
        })
        ,
        multiValue: (base) => ({
            ...base,
            background: '#ced4da',
            borderRadius: '0px',
            marginTop: '0px',
            fontWeight: '400',
            lineHeight: '18px',
            position: 'relative',
            top: '-2px',
            left: '-10px'
        })
    }

    const jobTypeOptions = [
        { label: 'Part Time', value: 'Part Time' },
        { label: 'Full Time', value: 'Full Time' },
        { label: 'Contract', value: 'Contract' },
    ]

    return (
        <div className={styles.addItemPopup}>
            <ToastContainer />
            <div className="row mt-3">
                <div className="col-md-12 text-center">
                    <h4 className={styles.itemDes}>
                        <span className={styles.pop_logo}><img src={ukbd} alt="" /> </span> <span className={styles.pop_title}>UK & EU Brands {'>'} Partner {'>'} Add New Job</span>
                        <span className={styles.itemDesIcon} onClick={() => navigate(-1)}><i className="fa-thin fa-xmark"></i></span>
                    </h4>
                </div>
                <div className="col-md-12">
                    <div className={styles.tabContainer}>
                        <div className={styles.tabHead}>
                            <div className={classNames(styles.tabs, activeTab === 1 ? styles.active : '')} onClick={() => selectTab(1)}>Details</div>
                            <div className={classNames(styles.tabs, activeTab === 2 ? styles.active : '')} onClick={() => selectTab(2)}>Description</div>
                            <div className={classNames(styles.tabs, activeTab === 3 ? styles.active : '')} onClick={() => selectTab(3)}>Images</div>
                            <div className={classNames(styles.tabs, activeTab === 4 ? styles.active : '')} onClick={() => selectTab(4)}>Others</div>
                        </div>
                        <div className={styles.tabBody}>
                            {activeTab === 1 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}>
                                    <div className={classNames(styles.form_container)}>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)} style={{ minHeight: '68px', height: 'unset' }}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Domain <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            // isMulti
                                                            value={selected_domain}
                                                            name="colors"
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            styles={selectStyles}
                                                            onChange={handleDomain}
                                                            options={domain_list}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job Title <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.name} name="name" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Item Name" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job ID <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.job_id} name="job_id" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Job ID" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job-category <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            defaultValue={selectedProduct}
                                                            onChange={(e) => handleOnChange(e, 'product_id')}
                                                            options={product_list}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Vacancy <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.vacancy} name="vacancy" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="No. of vacancy" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job Type <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        {/* <select value={form_data.job_type} name="job_type" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f">
                                                            <option value={''}>Select...</option>
                                                            <option value={'Part Time'}>Part Time</option>
                                                            <option value={'Full Time'}>Full Time</option>
                                                            <option value={'Contract'}>Contract</option>
                                                        </select> */}
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            // defaultValue={selectedProduct}
                                                            name="job_type"
                                                            onChange={(e) => handleOnChange(e, 'job_type')}
                                                            options={jobTypeOptions}
                                                            classNamePrefix="select"
                                                        />
                                                        {/* <input type="text" value={form_data.vacancy} name="vacancy" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="No. of vacancy" /> */}
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Salary Type<span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            // defaultValue={selectedProduct}
                                                            name="salary_time"
                                                            onChange={(e) => handleOnChange(e, 'salary_time')}
                                                            options={[{ label: 'Per Annum', value: 'Per Annum' }, { label: 'Per Hour', value: 'Per Hour' }]}
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Salary Range (From)<span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="number" value={form_data.salary_range_start} name="salary_range_start" onChange={(e) => handleOnChange(e)} onBlur={convertToPoint} className="form-control rounded-0 bs_input_f" placeholder="Starting range" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Salary Range (To)<span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="number" value={form_data.salary_range_end} name="salary_range_end" onChange={(e) => handleOnChange(e)} onBlur={convertToPoint} className="form-control rounded-0 bs_input_f" placeholder="Ending range" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Location <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.location} name="location" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Job location" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Deadline <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="date" value={form_data.deadline} name="deadline" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Item Name" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" checked={form_data.is_visible_in_store} name="is_visible_in_store" onChange={(e) => handleOnChange(e)} id="is_visible" />
                                                        <label className="form-check-label small" htmlFor="is_visible">Visible</label>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 2 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}>
                                    <div className={classNames(styles.form_container)}>
                                        <div className={classNames(styles.form_column)}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Short Description</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', minHeight: 'calc(100% - 25px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className="shot_desc" value={form_data.short_details} placeholder="Short Description" onChange={(e) => setShortDetailsContent(e)} />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.form_column)} style={{ width: '50%' }}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Full Description</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', minHeight: 'calc(100% - 25px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className="full_desc" value={form_data.details} placeholder="Details" onChange={(e) => setDetailsContent(e)} />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.form_column)}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Note</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', minHeight: 'calc(100% - 25px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className="full_note" value={form_data.note_for_item} placeholder="Write a note ..." onChange={(e) => setNoteContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 3 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}></div>
                            }
                            {activeTab === 4 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}></div>
                            }

                            <div className={styles.tab_footer_section}>
                                <button className={styles.leftArrow} style={activeTab === 1 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 1 ? prev - 1 : prev)}><i className="fa-thin fa-chevron-left"></i></button>
                                <div className={styles.footerMiddleSection}>
                                    <button className={styles.save_btn} onClick={reset_form} style={{ marginRight: 15 }}>Reset</button>
                                    <button className={styles.save_btn} onClick={saveNewItem} style={loader ? { backgroundColor: '#012965', color: '#fff', pointerEvents: 'none' } : {}}>{loader ? 'Saving...' : 'Save Item'}</button>
                                </div>
                                <button className={styles.RightArrow} style={activeTab === 4 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 4 ? prev + 1 : prev)}><i className="fa-thin fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddNewJob;
