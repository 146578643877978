/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from "react";
import classNames from "classnames";
import styles from "../../../assets/css/ProductItems.module.css";
import 'moment-timezone';
import { IMAGE_URL } from "../../../Config/Config";
import { getStoreData, get_item_list, get_product_list, update_item, update_item_image, get_store_detail, delete_item, get_domains } from '../../../Utils/method'
// import CropImage from "../../Popups/CropImage";
import Select from 'react-select';
import DeletePopup from "../../Popups/DeletePopup";
import { getCurrencySign } from "../../../Utils/common";
import Paginate from "../Paginate";
// import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ImagePreview from "./ImagePreview";
// import { Link } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import defaultImg from '../../../assets/default-image.png'
import ukbd from '../../../assets/UKBD.png'

const selectStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        // '&:focus': {
        //     border: '0 !important',
        // },
        background: '#fff',
        minHeight: '18px',
        border: '1px solid #ced4da',
        borderRadius: '2px',
    }),
    container: (baseStyles) => ({
        ...baseStyles,
        width: '100%',
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    }),
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 15px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',
    })
    ,
    multiValue: (base) => ({
        ...base,
        background: '#ced4da',
        borderRadius: '0px',
        marginTop: '0px',
        fontWeight: '400',
        lineHeight: '18px',
        position: 'relative',
        top: '-2px',
        left: '-10px'
    })
}

function Jobs() {
    const [store_data, setStore_data] = useState(getStoreData({ type: 'get' }));
    const [subCategories_all, setSubCategories_all] = useState([]);
    const [suppliers_all, setSuppliers_all] = useState([]);
    const [brands_all, setBrands_all] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [brands, setBrands] = useState([]);

    const [items, setItems] = useState([]);
    const [all_product_list, setAll_Product_list] = useState([]);
    const [product_list, setProduct_list] = useState([]);
    const [loader, setLoader] = useState(true);
    const [cropPopup, setCropPopup] = useState(false);
    const [expandedItem, setExpandedItem] = useState("");
    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [curentImage, setCurentImage] = useState('');
    const [previewPopup, setPreviewPopup] = useState(false);
    const [previewItem, setPreviewItem] = useState('')
    const [filterSubCate, setFilterSubCate] = useState([]);
    const [filterDomain, setFilterDomain] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [is_search, setIs_search] = useState(false);
    const [is_filtered, setIs_filtered] = useState(true);
    const [conversion_rate, setConversion_rate] = useState(store_data.store.conversion_rate);
    const [domain_list, setDomain_list] = useState([]);
    const sub_store = getStoreData('get').sub_store;

    const getProduct_list = async () => {
        try {
            let apires = await get_product_list({
                store_id: store_data.store._id,
                server_token: store_data.store.server_token,
            });
            if (apires.success) {
                let data = []
                let sort_v = apires.products.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                for (const p of sort_v) {
                    // {
                    //     "product_id": "64e5c601dbc86d3553601259"
                    // }
                    // if (p._id === "64e5c601dbc86d3553601259") {
                    data.push({ value: p._id, label: p.name[0] })
                    // filter_by_subcate({ value: p._id, label: p.name[0] })
                    // }
                }
                setProduct_list(data)
                setAll_Product_list(sort_v);
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const filterOnClick = async () => {
    //   setIs_filtered(true);
    //   setFilterSubCate('');
    //   // let is_show_to_slide = is_show_to_slide(true);
    //   getPaginate_data(1, 'filter', 'is_show_to_slide', true);
    // }

    const filter_by_subcate = async (data) => {
        setIs_filtered(true)
        setFilterSubCate(data)
        console.log('Call 01');
        getPaginate_data(1, 'filter', 'product_id', data.value)
    }
    const filter_by_domain = async (data) => {
        setIs_filtered(true)
        setFilterDomain(data)
        getPaginate_data(1, 'filter', 'domain_id', data.value)
    }

    const getPaginate_data = async (page, type, field, value) => {
        setLoader(true)
        try {
            // console.log(85, page, type);
            let data = {
                store_id: store_data.store._id,
                server_token: store_data.store.server_token,
                page: page,
                perPage: Number(itemPerPage),
                is_store: sub_store ? false : true,
                is_job: true
            }


            if (type === 'filter' || type === 'search') {
                let obj = {};
                obj[field] = value;
                data['search_options'] = obj
            }
            let apires = await get_item_list(data);
            setItems(apires.data.items);
            setCurrentPage(apires.data.currentPage)
            setTotalPage(apires.data.totalPages)
            if (type === 'reset') {
                setIs_search(false);
                setIs_filtered(false);
                setSearchTerm('')
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    }

    const searchOnKeyup = async (e) => {
        if (e.key === 'Enter') {
            if (searchTerm.trim().length > 0) {
                setIs_search(true)
                let name = searchTerm.replace(/&/g, "%26");
                console.log('Call 02');
                getPaginate_data(1, 'search', 'name', name.trim())
            } else {
                if (is_search) {
                    resetFilter();
                }
            }
        }
    }

    // const filterOnClick = async () => {
    //   if (searchTerm) {
    //     setIs_search(true);
    //     let is_show_to_slide = is_show_to_slide(true);
    //     getPaginate_data(1, 'search', 'is_show_to_slide');
    //   }
    //   else {
    //     if (is_search) {
    //       resetFilter();
    //     }
    //   }
    // }

    /*is_show_to_slide*/
    const searchOnClick = async () => {
        if (searchTerm.trim().length > 0) {
            setIs_search(true);
            let name = searchTerm.replace(/&/g, "%26");
            console.log('Call 03');
            getPaginate_data(1, 'search', 'name', name.trim());
        } else {
            if (is_search) {
                resetFilter();
            }
        }
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const resetFilter = () => {
        // getPaginate_data(1, 'reset')
        console.log('Call 04');
        getPaginate_data(1, 'filter', 'product_id', filterSubCate.value)
    }

    const handleChangePage = (page) => {
        setCurrentPage(page);
        if (is_search) {
            console.log('Call 05');
            getPaginate_data(page, 'search', 'name', searchTerm.trim())
        } else if (is_filtered) {
            console.log('Call 06');
            getPaginate_data(page, 'filter', 'product_id', filterSubCate.value)
        } else {
            console.log('Call 07');
            getPaginate_data(page, 'pagi')
        }
    };

    const get_store = async () => {
        try {
            let apires = await get_store_detail({ store_id: store_data.store._id })
            setStore_data(apires.data)
            setConversion_rate(apires.data.store.conversion_rate)
        } catch (error) {
            console.log(error);
        }
    }

    const get_domain_list = async () => {
        try {
            let apires = await get_domains();
            let data = []
            let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
            for (const p of sort_v) {
                data.push({ value: p._id, label: p.name })
            }
            setDomain_list(data)
        } catch (error) {
            console.log();
        }
    }

    useEffect(() => {
        get_store();
        get_domain_list();
        getProduct_list();
    }, [])

    useEffect(() => {
        setCurrentPage(1);
        // if (is_filtered) {
        getPaginate_data(1, 'filter', 'product_id', filterSubCate.value)
        // } else {
        //   getPaginate_data(1, 'initial')
        // }
    }, [itemPerPage])

    // Image Crop section
    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const deleteItemFunc = (e, v) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setSelectedItem(v)
        setDeleteStatus(true);
    }

    const deleteCancel = () => {
        setDeleteStatus(false);
    }

    const deleteItem = async () => {
        try {
            setDeleteStatus(false);
            let apires = await delete_item({ item_id: selectedItem._id })
            console.log('delete_item:', apires)
            if (apires.success) {
                toast.success('Item Deleted successfully', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setItems(items.filter(i => i._id !== selectedItem._id))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className={styles.itemList_page}>
                <ToastContainer />
                {loader && <div className="loaderContainer"></div>}
                <div className={styles.itemsPageHeader}>
                    <div className={styles.pageHeaderSection}>
                        <div className={styles.titleWithBC}>
                            <h3>Jobs</h3>
                            <ol className={styles.breadcrumb}>
                                <li className={styles.breadcrumb_item}>Home</li>
                                <li className={styles.breadcrumb_item}>Menu</li>
                                <li className={styles.breadcrumb_item}>Jobs</li>
                            </ol>
                        </div>
                    </div>
                    <div className={styles.pageHeaderSection} style={{ padding: 0 }}>
                        <div className={styles.nav} style={{ width: 'auto' }}>
                            <div className={styles.paginationSection}>
                                <Paginate
                                    pageCount={totalPage}
                                    onPageChange={handleChangePage}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={3}
                                    currentPage={currentPage}
                                />
                                <div className={styles.navItem}>
                                    <span className={styles.per_page}>Per Page</span>
                                    <select className={styles.btn_page} value={itemPerPage} onChange={(e) => setItemPerPage(e.target.value)}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.filterSection}>
                    <div className={styles.nav} style={{ width: 'auto' }}>
                        <div className={styles.nav_item_left}>
                            <div className={classNames(styles.navItem, 'select_filter')}>
                                <Select
                                    className={styles.filterSelect_item}
                                    value={filterSubCate}
                                    placeholder="Sub Cat"
                                    onChange={(e) => filter_by_subcate(e)}
                                    options={product_list}
                                />
                            </div>
                            <div className={classNames(styles.navItem, 'select_filter')}>
                                <Select
                                    className={styles.filterSelect_item}
                                    value={filterDomain}
                                    placeholder="Domain"
                                    onChange={(e) => filter_by_domain(e)}
                                    options={domain_list}
                                />

                            </div>

                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" value={searchTerm} onChange={handleChange} onKeyUp={searchOnKeyup} className={styles.searchbox} />
                                    {is_search ?
                                        <button className={styles.searchIcon} style={{ color: '#ff0000' }} type="submit" onClick={() => resetFilter()}>
                                            <i className="fa-thin fa-circle-xmark"></i>
                                        </button>
                                        :
                                        <button className={styles.searchIcon} type="submit" onClick={searchOnClick}>
                                            <span><i className="fa-light fa-magnifying-glass"></i></span>
                                        </button>}
                                </div>
                            </div>
                            <div className={styles.navItem}>
                                <div className={styles.isInSlide}>
                                    <button className={styles.resetFilters} onClick={() => { resetFilter() }} >Reset Filters</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.nav} style={{ width: 'auto' }}>
                        <div className={styles.paginationSection}>
                            <div className={styles.navItem}>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.itemsContainer} style={!loader && items.length === 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}}>
                    {!loader && items.length === 0 ? <h2 className="not_found_msg">No data found!</h2> : ''}
                    {!loader &&
                        items.map((v, i) => {
                            const dateObject = v.deadline ? new Date(v.deadline).toISOString().split('T')[0] : '';
                            let data = { ...v, deadline: dateObject }
                            return (
                                <DrawList sl={i + 1}
                                    v={data}
                                    key={v._id}
                                    subCategories={subCategories}
                                    subCategories_all={subCategories_all}
                                    suppliers={suppliers}
                                    suppliers_all={suppliers_all}
                                    brands={brands}
                                    brands_all={brands_all}
                                    deleteItemFunc={deleteItemFunc}
                                    expandedItem={expandedItem}
                                    setExpandedItem={setExpandedItem}
                                    onCropChange={onCropChange}
                                    // onCropComplete={onCropComplete}
                                    setCropPopup={setCropPopup}
                                    setCurentImage={setCurentImage}
                                    // selectedImage={selectedImage}
                                    // setSelectedImage={setSelectedImage}
                                    setPreviewPopup={setPreviewPopup}
                                    setPreviewItem={setPreviewItem}
                                    product_list={product_list}
                                    all_product_list={all_product_list}
                                    store_data={store_data}
                                    conversion_rate={conversion_rate}
                                    setItems={setItems}
                                    items={items}
                                    getPaginate_data={getPaginate_data}
                                    page={currentPage}
                                    is_search={is_search}
                                    searchTerm={searchTerm}
                                    is_filtered={is_filtered}
                                    filterSubCate={filterSubCate}
                                    ukbd={ukbd}
                                    domain_list={domain_list}
                                />
                            )
                        })}
                </div>

                {deleteStatus && <DeletePopup deleteCancel={deleteCancel} deleteItem={deleteItem} />}
                {/* {previewPopup && <ImagePreview previewItem={previewItem} setPreviewPopup={setPreviewPopup} />} */}
            </div>
            {/* {cropPopup && <CropImage setCropPopup={setCropPopup} cropConfirm={cropConfirm} curentImage={curentImage} />} */}
        </>
    )
}

const DrawList = (props) => {
    const [currentItem, setCurrentItem] = useState({
        ...props.v,
        salary_range_start: Number(props.v.salary_range_start).toFixed(2),
        salary_range_end: Number(props.v.salary_range_end).toFixed(2),
        short_details: props.v.short_details ? props.v.short_details : []
    })

    const [selectedProduct, setSelectedProduct] = useState(props.product_list.filter(i => i.value === props.v.product_id)[0]);

    const [activeTab, setActiveTab] = useState(1)
    const [itemUpdateButton, setItemUpdateButton] = useState(false)
    const [uploadableImage, setUploadableImage] = useState([]);
    const [loader, setLoader] = useState(false);

    const selectTab = (e) => {
        setActiveTab(e);
    }

    const updateOnChange = async (e, ext_name) => {
        const { name, value } = e.target;
        console.log(name, value)
        if (name === 'is_item_in_stock' || name === 'is_visible_in_store' || name === 'is_most_popular' || name === 'is_show_to_slide') {
            setCurrentItem({ ...currentItem, [name]: e.target.checked });
        } else if ((name === 'offer_percentage') && ext_name !== undefined) {
            setCurrentItem({ ...currentItem, [name]: ext_name });
        } else {
            setCurrentItem({ ...currentItem, [name]: value })
        }
    }

    const setNoteContent = (e) => {
        setCurrentItem({ ...currentItem, note_for_item: e });
    }

    const setShortDetailsContent = (e) => {
        setCurrentItem({ ...currentItem, short_details: e });
    }

    const setDetailsContent = (e) => {
        setCurrentItem({ ...currentItem, details: e });
    }

    const SelectOnChange = (e, type) => {
        console.log(e, type)
        if (type === 'product_id') {
            let data = props.all_product_list.filter(i => i._id === e.value)
            console.log(411, data, currentItem);
            setCurrentItem({
                ...currentItem,
                [type]: e.value,
                product_sequence_number: data[0].sequence_number,
                sequence_number: Number(data[0].sequence_number.toString() + currentItem.actual_sequence_number.toString())
            });
            setSelectedProduct(e)
        } else if (type === 'salary_time') {
            setCurrentItem({
                ...currentItem,
                [type]: e.value,
            });
            setSelected_salary_type(e)
        }
    }

    const updateCurrentItem = (e) => {
        if (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
        if (props.is_search) {
            props.getPaginate_data(props.page, 'search', 'name', props.searchTerm.trim())
        } else if (props.is_filtered) {
            props.getPaginate_data(props.page, 'filter', 'product_id', props.filterSubCate.value)
        } else {
            props.getPaginate_data(props.page, 'pagi')
        }
    }

    const updateOnSubmit = async (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        console.log(456, props.store_data);
        const data = new FormData();

        for (let i = 0; i < currentItem.domain_ids.length; i++) {
            data.append('domain_ids[' + i + ']', currentItem.domain_ids[i]);
        }
        data.append('_id', currentItem._id);
        data.append('item_id', currentItem._id);
        data.append('store_id', props.store_data.store._id);
        data.append('server_token', props.store_data.store.server_token);
        data.append('timezone', props.store_data.timezone);
        currentItem.name !== props.v.name && data.append('name[0]', currentItem.name);
        currentItem.short_details !== props.v.short_details && data.append('short_details[0]', currentItem.short_details);
        currentItem.details !== props.v.details && data.append('details[0]', currentItem.details);
        currentItem.product_sequence_number !== props.v.product_sequence_number && data.append('product_sequence_number', currentItem.product_sequence_number);
        currentItem.note_for_item !== props.v.note_for_item && data.append('note_for_item', currentItem.note_for_item);
        currentItem.is_visible_in_store !== props.v.is_visible_in_store && data.append('is_visible_in_store', currentItem.is_visible_in_store);
        currentItem.product_id !== props.v.product_id && data.append('product_id', currentItem.product_id);
        currentItem.salary_time !== props.v.salary_time && data.append('salary_time', currentItem.salary_time);
        currentItem.salary_range_start !== props.v.salary_range_start && data.append('salary_range_start', currentItem.salary_range_start);
        currentItem.salary_range_end !== props.v.salary_range_end && data.append('salary_range_end', currentItem.salary_range_end);
        currentItem.location !== props.v.location && data.append('location', currentItem.location);
        currentItem.deadline !== props.v.deadline && data.append('deadline', currentItem.deadline);
        currentItem.job_id !== props.v.job_id && data.append('job_id', currentItem.job_id);
        currentItem.job_type !== props.v.job_type && data.append('job_type', currentItem.job_type);
        currentItem.vacancy !== props.v.vacancy && data.append('vacancy', currentItem.vacancy);
        data.append('input_price', '0');
        data.append('input_price_multi', '0');
        data.append('input_price_plus', '0');

        try {
            let apires = await update_item(data);
            // console.log(332, apires)
            if (apires.data.success) {
                updateCurrentItem()
                toast.success('Item Update successfully', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

                props.setExpandedItem('')
                setItemUpdateButton(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const update_image = async () => {
        setLoader(true)
        try {
            const data = new FormData();
            data.append('image_url[0]', uploadableImage[0]);
            data.append('item_id', currentItem._id);
            data.append('store_id', props.store_data.store._id);
            data.append('servertoken', props.store_data.store.server_token);
            data.append('timezone', props.store_data.timezone);

            let apires = await update_item_image(data);
            console.log(527, apires.data.item.image_url);
            if (apires.data.success) {
                toast.success('Item Update successfully', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setCurrentItem({ ...currentItem, image_url: apires.data.item.image_url })
            }
            setUploadableImage([])
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    useEffect(() => {
        if (uploadableImage.length > 0) {
            update_image()
        }
    }, [uploadableImage])

    // const delete_image = async (d) => {
    //     console.log(d);
    //     const data = new FormData();
    //     data.append('image_url[0]', d);
    //     data.append('_id', currentItem._id);
    //     data.append('store_id', props.store_data.store._id);
    //     data.append('server_token', props.store_data.store.server_token);
    //     data.append('timezone', props.store_data.timezone);

    //     try {
    //         let apires = await delete_item_image(data)
    //         console.log(560, apires);
    //         if (apires.data.success) {
    //             setCurrentItem({ ...currentItem, image_url: currentItem.image_url.filter(f => f !== d) })
    //             toast.success('Image removed successfully', {
    //                 position: 'top-right',
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true
    //             });
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    useEffect(() => {
        // currentItem.short_details
    }, [currentItem])

    useEffect(() => {
        let buttonStatus = false;
        if (currentItem.name !== props.v.name) {
            buttonStatus = true
        } else if (currentItem.short_details !== props.v.short_details) {
            buttonStatus = true;
        } else if (currentItem.details !== props.v.details) {
            buttonStatus = true;
        } else if (currentItem.note_for_item !== props.v.note_for_item) {
            buttonStatus = true;
        } else if (currentItem.product_sequence_number !== props.v.product_sequence_number) {
            buttonStatus = true;
        } else if (currentItem.is_visible_in_store !== props.v.is_visible_in_store) {
            buttonStatus = true;
        } else if (currentItem.salary_time !== props.v.salary_time) {
            buttonStatus = true;
        } else if (currentItem.salary_range_start !== props.v.salary_range_start) {
            buttonStatus = true;
        } else if (currentItem.salary_range_end !== props.v.salary_range_end) {
            buttonStatus = true;
        } else if (currentItem.location !== props.v.location) {
            buttonStatus = true;
        } else if (currentItem.deadline !== props.v.deadline) {
            buttonStatus = true;
        } else if (currentItem.job_id !== props.v.job_id) {
            buttonStatus = true;
        } else if (currentItem.job_type !== props.v.job_type) {
            buttonStatus = true;
        } else if (currentItem.vacancy !== props.v.vacancy) {
            buttonStatus = true;
        } else if (currentItem.domain_id !== props.v.domain_id) {
            buttonStatus = true;
        } else {
            buttonStatus = false;
        }

        setItemUpdateButton(buttonStatus)
    }, [currentItem])

    let serial = props.sl.toString();
    if (serial.length < 4) {
        serial = serial.padStart(4, '0');
    }

    const onBlurWeight = (e) => {
        const { name, value } = e.target;
        setCurrentItem({ ...currentItem, [name]: Number(value).toFixed(3) });
    }

    const salaryTypes = [{ label: 'Per Annum', value: 'Per Annum' }, { label: 'Per Hour', value: 'Per Hour' }]
    const [selected_salary_type, setSelected_salary_type] = useState([]);
    const [selected_domain, setSelected_domain] = useState([]);

    useEffect(() => {
        let _domain = []
        for (const key in props.domain_list) {
            const domain = props.domain_list[key];
            // for (const i in props.v.domain_ids) {
            //     const id = props.v.domain_ids[i];
            //     if (domain.value === id) {
            //         _domain.push(domain)
            //     }
            // }
            props.v.domain_id && domain.value === props.v.domain_id && _domain.push(domain);
        }
        setSelected_domain(_domain)

        for (const key in salaryTypes) {
            const st = salaryTypes[key];
            if (st.value === props.v.salary_time) {
                setSelected_salary_type(st)
            }
            // for (const i in props.v.domain_ids) {
            //     const id = props.v.domain_ids[i];
            //     if (domain.value === id) {
            //         _domain.push(domain)
            //     }
            // }
        }
    }, [])

    const handleDomain = (d) => {
        console.log(d);
        setSelected_domain(d)
        // let data = []
        // for (const iterator of d) {
        //     data.push(iterator.value)
        // }
        setCurrentItem({ ...currentItem, domain_id: d.value })
    }

    return (
        <div className={classNames(styles.mainItems)}>

            <div className={styles.itemSection} onClick={() => props.setExpandedItem((prev) => (prev === currentItem._id ? "" : currentItem._id))}>
                <div className={classNames(styles.headerOne)} style={{ pointerEvents: 'none' }}>
                    <div className={styles.numberSection}>
                        <div>{serial}</div>
                        <div className={styles.combineField}>
                            <div>{currentItem.product_sequence_number}</div>
                            <input type={'text'} value={currentItem.actual_sequence_number} onChange={(e) => updateOnChange(e)} name="actual_sequence_number" className="text-center" />
                        </div>
                    </div>
                    <Select
                        className={styles.customSelect_item}
                        defaultValue={selectedProduct}
                        onChange={(e) => SelectOnChange(e, 'product_id')}
                        options={props.product_list}
                    />
                </div>
                <div className={classNames(styles.headerTwo)}>
                    <div className={styles.itemImage}>
                        <img
                            src={IMAGE_URL + currentItem.image_url[0]}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = defaultImg;
                            }}
                            alt={"item"} />
                    </div>
                    <div className={styles.itemTitle}>
                        <p>{currentItem.name}</p>
                    </div>
                </div>
                <div className={classNames(styles.headerThree)} style={{ pointerEvents: 'none' }}>
                    <p>  {selected_domain.length > 0 ? (
                        selected_domain.map((domain, index) => (
                            <p key={index}>{domain.label}</p>
                        ))
                    ) : (
                        <p>...</p>
                    )}</p>
                    {/* domain data should be added here */}
                </div>
                <div className={classNames(styles.itemPrice, styles.headerFour)} style={{ width: '220px' }}>
                    {currentItem.salary_range_end === '' || currentItem.salary_range_end === 0 || currentItem.salary_range_end === '0' || currentItem.salary_range_end === '0.00' ?
                        <p> {getCurrencySign('GBP')} {currentItem.salary_range_start} ({currentItem.salary_time}) </p>
                        :
                        <p> {getCurrencySign('GBP')} {currentItem.salary_range_start} to {getCurrencySign('GBP')} {currentItem.salary_range_end} {(currentItem.salary_time !== '' && currentItem.salary_time !== null && currentItem.salary_time !== undefined) ? '(' + currentItem.salary_time + ')' : ''} </p>
                    }
                    {/* <p> {getCurrencySign('BDT')}  {priceConvertToAlternate(currentItem.salary_range_start, 'GBP', 136.55)} -  {getCurrencySign('BDT')}  {priceConvertToAlternate(currentItem.salary_range_end, 'GBP', 136.55)} </p> */}
                </div>
                <div className={classNames(styles.headerFive)}>
                    <span onClick={(e) => props.deleteItemFunc(e, currentItem)} style={{ color: '#ff0000' }}> <i className="fa-thin fa-trash-can"></i></span>
                </div>
            </div>
            {props.expandedItem === currentItem._id && (
                <div className={classNames(styles.expandSection, styles.item_editable)}>
                    <div className={styles.edit_item_header}>
                        <span className={styles.pop_logo}>
                            <img src={props.ukbd} alt="" />
                        </span>
                        <span className={styles.pop_title}>UK & EU Brands {'>'} Partner {'>'} Edit Job</span>
                        <span className={styles.popup_close} onClick={() => props.setExpandedItem((prev) => (prev === currentItem._id ? "" : currentItem._id))}><i className="fa-thin fa-xmark"></i></span>
                    </div>
                    <div className={styles.tabContainer}>
                        <div className={styles.tabHead}>
                            <div className={classNames(styles.tabs, activeTab === 1 ? styles.active : '')} onClick={() => selectTab(1)}>Details</div>
                            <div className={classNames(styles.tabs, activeTab === 2 ? styles.active : '')} onClick={() => selectTab(2)}>Description</div>
                            <div className={classNames(styles.tabs, activeTab === 3 ? styles.active : '')} onClick={() => selectTab(3)}>Images</div>
                            <div className={classNames(styles.tabs, activeTab === 4 ? styles.active : '')} onClick={() => selectTab(4)}>Others</div>
                        </div>
                        <div className={styles.tabBody}>
                            {loader && <div className="loaderContainer"></div>}
                            {activeTab === 1 &&
                                <div className="tab-pane fade show active" id="home">
                                    <div className={classNames(styles.form_container)}>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job Title</label>
                                                    <div className="input-group">
                                                        <input type="text" value={currentItem.name} name="name" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Item Name" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job ID</label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={currentItem.job_id} name="job_id" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Job ID" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job-Category</label>
                                                    <div className="input-group">
                                                        <Select
                                                            className={styles.customSelect_item}
                                                            value={selectedProduct}
                                                            onChange={(e) => SelectOnChange(e, 'product_id')}
                                                            options={props.product_list}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Vacancy</label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={currentItem.vacancy} name="vacancy" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="No. of vacancy" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Job Type</label>
                                                    <div className="input-group mt-0">
                                                        <select value={currentItem.job_type} name="job_type" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f">
                                                            <option value={''}>Select...</option>
                                                            <option value={'Part Time'}>Part Time</option>
                                                            <option value={'Full Time'}>Full Time</option>
                                                            <option value={'Contract'}>Contract</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Salary Type<span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            value={selected_salary_type}
                                                            name="salary_time"
                                                            onChange={(e) => SelectOnChange(e, 'salary_time')}
                                                            options={salaryTypes}
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Salary Range (From)</label>
                                                    <div className="input-group mt-0">
                                                        <input type="number" value={currentItem.salary_range_start} name="salary_range_start" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Starting range" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Salary Range (To)</label>
                                                    <div className="input-group mt-0">
                                                        <input type="number" value={currentItem.salary_range_end} name="salary_range_end" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Ending range" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Location </label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={currentItem.location} name="location" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Job location" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" checked={currentItem.is_visible_in_store} name="is_visible_in_store" onChange={(e) => updateOnChange(e)} id="is_visible" />
                                                        <label className="form-check-label small" htmlFor="is_visible">Visible</label>
                                                    </div>
                                                </div>

                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Deadline</label>
                                                    <div className="input-group mt-0">
                                                        <input type="date" value={currentItem.deadline} name="deadline" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Item Name" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)} style={{ minHeight: '68px', height: 'unset' }}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Domain <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            // isMulti
                                                            name="colors"
                                                            value={selected_domain}
                                                            options={props.domain_list}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            styles={selectStyles}
                                                            onChange={handleDomain}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 2 &&
                                <div className="tab-pane fade show active" id="profile">
                                    <div className={classNames(styles.form_container)}>
                                        <div className={classNames(styles.form_column)}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Short Description</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className={'item_editor'} value={currentItem.short_details.toString()} placeholder="Short Description" onChange={(e) => setShortDetailsContent(e)} />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.form_column)} style={{ width: '50%' }}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Full Description</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className={'item_editor'} value={currentItem.details.toString()} placeholder="Item Details" onChange={(e) => setDetailsContent(e)} />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.form_column)}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Note</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className={'item_editor'} value={currentItem.note_for_item} placeholder="Write a note ..." onChange={(e) => setNoteContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 3 &&
                                <div className="tab-pane fade show active" id="contact">
                                    <div className={classNames(styles.form_container)}>
                                        {/* <div className={styles.imageDropDownContainer}
                                            onPaste={handlePaste}
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragLeave} >
                                            {currentItem.image_url.length > 0 ?
                                                currentItem.image_url.filter(i => removedImage.indexOf(i) === -1).map((v, i) =>
                                                    <div className={styles.uploadedImage} key={i + '_prev_img'}>
                                                        <div className={styles.uploadedInner}>
                                                            <img src={IMAGE_URL + v} alt="Preview" />
                                                            <span className={styles.deleteImgIcon} onClick={() => delete_image(v)}><i className="fa-light fa-trash-can"></i></span>
                                                        </div>
                                                    </div>
                                                )
                                                : ''
                                            }
                                            <label className={styles.uploaderDiv} htmlFor={'imageUpload'}>
                                                <input type={'file'} name={'imageUpload'} id={'imageUpload'} onChange={handleImageChange} accept="image/*" hidden />
                                                <span className={styles.addIcon}>
                                                    <i className="fa-thin fa-circle-plus"></i>
                                                </span>
                                                <span className={styles.addLabel}>Add Image</span>
                                            </label>
                                        </div> */}
                                    </div>
                                </div>
                            }
                            {activeTab === 4 &&
                                <div className="tab-pane fade show active" id="profile">
                                    <div className={classNames(styles.form_container)}>
                                        {/* <div className="col-md-12">
                      
                    </div> */}
                                    </div>
                                </div>
                            }

                            <div className={styles.tab_footer_section}>
                                <button className={styles.leftArrow} style={activeTab === 1 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 1 ? prev - 1 : prev)}><i className="fa-thin fa-chevron-left"></i></button>
                                {itemUpdateButton && <div className={styles.footerMiddleSection}>
                                    <button className={styles.save_btn} onClick={updateCurrentItem} style={{ marginRight: 15 }}>Reset</button>
                                    <button className={styles.save_btn} onClick={updateOnSubmit} style={loader ? { backgroundColor: '#012965', color: '#fff', pointerEvents: 'none' } : {}}>{loader ? 'Updating...' : 'Update'}</button>
                                </div>}
                                <button className={styles.RightArrow} style={activeTab === 4 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 4 ? prev + 1 : prev)}><i className="fa-thin fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="card-body table-responsive">
            <table className="table orderlist_table">
              <thead className={styles.thead_light}>
                <tr className={styles.table_heads}>
                  <th>Created at</th>
                  <th>Created by</th>
                  <th>Updated at</th>
                  <th>Updated by</th>
                  <th>Approved by</th>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                <tr className="">
                  <td> {currentItem.created_at !== null && moment(currentItem.created_at).format('lll')}</td>
                  <td>{currentItem.created_by}</td>
                  <td> {currentItem.updated_at !== null && moment(currentItem.updated_at).format('lll')}</td>
                  <td>{currentItem.updated_by}</td>
                  <td>{currentItem.approved_by}</td>
                </tr>
              </tbody>
            </table>
          </div> */}

                </div>
            )}
        </div>
    )
}

export default memo(Jobs)