import React, { useState } from 'react'
import styles from './Payment.module.css'
import Payment from './Payment'

export default function PaymentPage() {
    const [popup, setpopup] = useState(false)
    return (
        <div className={styles.payment_page}>
            <div className={`card rounded-0 ${styles.payment_card_section}`}>
                <div className='card-body'>
                    <p>Balance : $ 0000.00</p>
                    <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setpopup(true)}>Add balance</p>
                </div>
            </div>
            <div className={`card rounded-0 ${styles.payment_table_section}`}>
                <div className={`card-body table-responsive`}>
                    <table className='table'>
                        <thead className={styles.table_heads}>
                            <tr>
                                <th>Sl.</th>
                                <th>Description</th>
                                <th>Payment Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>x</td>
                                <td>xxxx xxxx xxxx xxxx</td>
                                <td>xx.xx.xxxx</td>
                                <td>$ xxxxxx</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {popup && <div className={styles.payment_popup_wrap}>
                <Payment setpopup={setpopup} />
            </div>}
        </div>
    )
}
