import { postMETHOD, getMETHOD } from "./http_helper";
import { v4 as uuidv4 } from "uuid";

export const getDeviceToken = () => {
  const device_token = localStorage.getItem("device_token");
  if (device_token) {
    return device_token;
  } else {
    let c = uuidv4();
    localStorage.setItem("device_token", c);
    return c;
  }
};

export const getStoreData = (params_data) => {
  if (params_data.type === "set") {
    localStorage.setItem("user_data", JSON.stringify(params_data.data));
    return params_data.data;
  } else {
    const user = localStorage.getItem("user_data");
    if (user) {
      return JSON.parse(user);
    } else {
      return false;
    }
  }
};

export const UserLogin = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let loginData = await postMETHOD("login", params_data);

      resolve(loginData.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};
export const SubUserLogin = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let loginData = await postMETHOD("sub_login", params_data);

      resolve(loginData.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};

export const logout = () => {
  localStorage.removeItem("user_data");
  const user_data = localStorage.getItem("user_data");
  if (user_data === null) {
    return true;
  } else {
    return false;
  }
};

export const register_sub_store = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("register_sub_store", params_data);

      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const register_sub_store_verify = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("register_sub_store_verify", params_data);

      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const UserRegister = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("register", params_data);

      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};

export const update = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("update", params_data);

      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};

export const delivery_list = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("delivery_list");
      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};

export const country_list = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("country_list");
      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};
export const city_list = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("city_list");
      console.log(25, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};

export const get_store_detail = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("get_store_detail", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_store_data = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_store_data", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const store_change_delivery_address = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD(
        "store_change_delivery_address",
        params_data
      );
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_item_in_cart = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_item_in_cart", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_import_initial_data = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("get_import_initial_data");
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};
export const get_import_initial_jobs_data = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("get_import_initial_jobs_data");
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_import_items = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_import_items", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};
export const get_import_jobs = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_import_jobs", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const import_selected_item_from_product = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD(
        "import_selected_item_from_product",
        params_data
      );
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};
export const import_selected_item_from_jobs = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD(
        "import_selected_item_from_jobs",
        params_data
      );
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_new_job = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_new_job", params_data);
      console.log("apires", apires);
      if (apires.data) {
        resolve(apires.data);
      } else {
        resolve(apires);
      }
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_item = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_item", params_data);
      console.log("apires", apires);
      if (apires.data) {
        resolve(apires.data);
      } else {
        resolve(apires);
      }
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_item_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("get_item_list_paginate", params_data);
      // console.log(100, data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const update_item = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("update_item", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const update_item_image = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("update_item_image", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const delete_item_image = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("delete_item_image", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_product_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_product_list", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const list_orders = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("list_orders", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const set_order_status = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("set_order_status", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const complete_order = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("complete_order", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const list_earning = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("list_earning", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const list_orders_history = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("list_orders_history", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_sub_store = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_sub_store", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const update_sub_store = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("update_sub_store", params_data);
      console.log(390, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(390, error);
      reject(error);
    }
  });
};

export const sub_store_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("sub_store_list", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_store_product_item_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("get_store_product_item_list", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_new_sub_category = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_new_sub_category", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data"))
            .server_token,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_new_brand = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_new_brand", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};

export const add_product_group_data = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_product_group_data", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_product_group_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_product_group_list", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const update_product_group = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("update_product_group", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_product = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_product", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_product_data = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_product_data", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const update_product = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("update_product", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const delete_product = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("delete_product", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const edit_brand = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("edit_brand", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data"))
            .server_token,
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const delete_brand = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("delete_brand", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data"))
            .server_token,
        },
      });

      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_new_supplier = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_new_supplier", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data"))
            .server_token,
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const edit_supplier = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("edit_supplier", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data"))
            .server_token,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const delete_supplier = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("delete_supplier", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data"))
            .server_token,
        },
      });

      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const delete_item = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("delete_item", params_data, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("user_data")).token,
        },
      });
      resolve({
        success: true,
        item: apires.data,
      });
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const store_conversion_rate_update = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD(
        "store_conversion_rate_update",
        params_data,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("user_data")).token,
          },
        }
      );
      console.log(481, apires);
      resolve({
        success: true,
        // item: apires.data
      });
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_main_category = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await getMETHOD("get_main_category");
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_sub_category = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await getMETHOD("get_sub_category");
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_brand = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await getMETHOD("get_brand", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_supplier = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await getMETHOD("get_supplier", params_data);
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_promo = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_promo", params_data);
      resolve(apires);
    } catch (error) {
      console.log(error);
      resolve({ success: false });
    }
  });
};

export const search_sort_promo_code_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("search_sort_promo_code_list", params_data);
      resolve(apires);
    } catch (error) {
      console.log(error);
      resolve({ success: false });
    }
  });
};

export const payment_gateway_list = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("payment_gateway_list");
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      resolve({ success: false });
    }
  });
};

export const get_today_activity = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await getMETHOD("get_today_activity");
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_report = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await getMETHOD(
        "get_report",
        `?start=${params_data.start}&end=${params_data.end}&page=1&perPage=100`
      );
      resolve(data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_active_vehicle_list = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_active_vehicle_list", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_new_domain = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_new_domain", params);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const edit_domain = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("edit_domain", params);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const delete_domain = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("delete_domain", params);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const get_domains = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("get_domains");
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const searchItems = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = await postMETHOD("search_items", {
        store_id: params.store_id,
        search_text: params.search_text,
        page: params.page,
        limit: params.limit,
      });
      console.log(890, data);
      if (data.data.success) {
        resolve(data.data);
      } else {
        resolve({ success: false, status_phrase: "Something Wrong!" });
      }
    } catch (error) {
      console.log(error);
      resolve({ success: false, status_phrase: "Something Wrong!" });
    }
  });
};

export const upload_file = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("upload_file", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false });
    }
  });
};

export const add_new_form = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("add_new_form", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const duplicate_form = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("duplicate_form", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const form_type_model = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("form_type_model");
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const get_applied_data = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_applied_data", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const delete_form = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("delete_form", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const get_single_forms = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_single_forms", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const get_single_public_form = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(31, params_data);
      let apires = await postMETHOD("get_single_public_form", params_data);
      console.log(32, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject(error.response.data);
    }
  });
};
export const edit_form = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("edit_form", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const submitPublicForm = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("submit_public_form", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const get_submited_applications = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("get_submited_applications", data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const send_link_method = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("send_link", data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const get_forms = (params = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("get_forms", params);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const upload_fileFORM = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("upload_file_form", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const email_list = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await getMETHOD("email_list");
      console.log(982, apires);
      resolve(apires.data);
    } catch (error) {
      console.log(error);
      reject({ success: false, status_phrase: "Something Wrong!!" });
    }
  });
};
export const update_email = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("update_email", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
export const upload_file_application = (params_data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let apires = await postMETHOD("upload_file_application", params_data);
      resolve(apires.data);
    } catch (error) {
      console.log(error.response.data);
      reject(error.response.data);
    }
  });
};
