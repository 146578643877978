/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from '../../../../assets/css/ImportNewItems.module.css'
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import ukbd from '../../../../assets/UKBD.png'
import classNames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStoreData, get_domains, get_import_initial_data, get_import_items, get_product_group_list, get_product_list, import_selected_item_from_product, get_brand } from "../../../../Utils/method";
import { IMAGE_URL, PRODUCT_URL } from "../../../../Config/Config";
import Paginate from "../../Paginate";

const ImportNewItems = () => {
    const navigate = useNavigate()
    const store_data = getStoreData({ type: 'get' })
    const [loader, setLoader] = useState(true)
    const [categories, setCategories] = useState([])
    const [sub_categories, setSub_Categories] = useState([])
    const [filter_subCategories, setFilter_subCategories] = useState([])
    const [selected_subCategory, setSelected_subCategory] = useState([])

    const [db_categories, setDb_Categories] = useState([])
    const [selected_dbCategory, setSelected_dbCategory] = useState([])
    const [db_sub_categories, setDb_Sub_Categories] = useState([])
    const [db_filter_sub_categories, setDb_filterSub_Categories] = useState([])
    const [db_filter_brands, setDb_filterBrands] = useState([])
    const [selected_dbsubCategory, setSelected_dbsubCategory] = useState([])
    const [selected_dbBrand, setSelected_dbBrand] = useState([])
    const [db_items, setDB_items] = useState([])
    const [db_selected_items, setDB_selected_items] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(30);
    const [is_visible, setIs_visible] = useState(false);
    const [warning, setWarning] = useState({ status: false, type: '' });
    const [domain_list, setDomain_list] = useState([]);
    const [brand_list, setBrands] = useState([]);
    const [selected_brand, setSelected_brand] = useState(null);
    const [selected_domain, setSelected_domain] = useState(null);
    const [is_search, setIs_search] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const resetFilter = () => {
        getImportItems(1, '', '', '', 'reset')
    }
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const searchOnClick = async () => {
        if (searchTerm.trim().length > 0) {
            setIs_search(true);
            let name = searchTerm.replace(/&/g, "%26");
            getImportItems(1, '', '', name)
        }
        else {
            if (is_search) {
                resetFilter();
            }
        }
    }
    const getImport_initial_data = async () => {
        try {
            let apires = await get_import_initial_data()
            console.log('get_import_initial_data:', apires);

            let data_2 = []
            let sort_v_2 = apires.main_categories.sort((a, b) => a.name.localeCompare(b.name));
            for (const p of sort_v_2) {
                data_2.push({ value: p._id, label: p.name, store_id: p.store_id, sub_category_ids: [...new Set(p.sub_category_ids)] })
            }
            setDb_Categories([...data_2])

            let data_3 = []
            let sort_v_3 = apires.sub_categories.sort((a, b) => a.name.localeCompare(b.name));
            for (const p of sort_v_3) {
                data_3.push({ value: p._id, label: p.name, store_id: p.store_id })
            }
            setDb_Sub_Categories([...data_3])

            let data_4 = []
            let sort_v_4 = apires.brands.sort((a, b) => a.name.localeCompare(b.name));
            for (const p of sort_v_4) {
                data_4.push({ value: p._id, label: p.name, store_id: p.store_id })
            }
            setDb_filterBrands([...data_4])
        } catch (error) {
            console.log(error);
        }
    }

    const filter_by_dbcategory = (d) => {
        setSelected_dbCategory(d)
        setSelected_dbsubCategory([])
        setDB_items([])
        setDB_selected_items([])
        let data = []

        for (const key in db_sub_categories) {
            const sub_category = db_sub_categories[key];
            for (const i in d.sub_category_ids) {
                const id = d.sub_category_ids[i];
                if (sub_category.value === id) {
                    data.push(sub_category)
                }
            }
        }
        // console.log(111, data);
        // setDb_filterSub_Categories
        setDb_filterSub_Categories(data)
    }

    const getImportItems = async (page, id, brand_id = '', name = '', reset = "") => {
        setLoader(true)
        try {
            let json = {

                limit: itemPerPage.toString(),
                page: page.toString(),
                // search: "urge"
            }
            if (id !== '') {
                json['sub_cate_id'] = id
            }
            if (brand_id !== '') {
                json['brand_id'] = brand_id
            }
            if (name !== '') {
                json['search'] = name
            }


            let apires = await get_import_items(json)
            console.log('get_import_items', apires);
            setCurrentPage(apires.page)
            setTotalPage(apires.totalPages)
            setTotalItems(apires.totalItems)
            setDB_items(apires.items)
            setLoader(false)
            if (reset === 'reset') {
                setIs_search(false);
                // setIs_filtered(false);
                setSearchTerm('')
            }
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const filter_by_dbsubcategory = async (d) => {
        console.log('filter_by_dbsubcategory', d);
        setSelected_dbsubCategory(d)
        getImportItems(1, d.value)
    }
    const filter_by_db_Brands = async (d) => {
        console.log('filter_by_dbsubcategory', d);
        setSelected_dbBrand(d)
        getImportItems(1, '', d.value)
    }

    const searchOnKeyup = async (e) => {
        if (e.key === 'Enter') {
            if (searchTerm.trim().length > 0) {
                setIs_search(true)
                let name = searchTerm.replace(/&/g, "%26");
                getImportItems(1, '', '', name)
            } else {
                if (is_search) {
                    resetFilter()
                }
            }
        }
    }

    useEffect(() => {
        if (db_items.length > 0) {
            getImportItems(1, selected_dbsubCategory.value)
        }
    }, [itemPerPage])

    const handleChangePage = (page) => {
        setCurrentPage(page);
        getImportItems(page, selected_dbsubCategory.value ? selected_dbsubCategory.value : '', selected_dbBrand.value ? selected_dbBrand.value : '', searchTerm)
        // if (is_search) {
        //     getPaginate_data(page, 'search', 'name', searchTerm.trim())
        // } else if (is_filtered) {
        //     getPaginate_data(page, 'filter', 'product_id', filterSubCate.value)
        // } else {
        //     getPaginate_data(page, 'pagi')
        // }
    };

    const select_all_items = () => {
        if (db_items.length > 0 && (db_items.length !== db_selected_items.length)) {
            let data = []
            for (const key in db_items) {
                if (Object.hasOwnProperty.call(db_items, key)) {
                    const item = db_items[key];
                    data.push(item._id)
                }
            }
            setDB_selected_items(data)
        } else {
            setDB_selected_items([])
        }
    }

    const get_sub_category = async () => {
        setLoader(true);
        try {
            let apires = await get_product_list({
                store_id: store_data.store._id,
                server_token: store_data.store.server_token,
            })
            console.log(59, apires);
            if (apires.success) {
                let data = []
                let sort_v = apires.products.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                for (const p of sort_v) {
                    data.push({ value: p._id, label: p.name[0], sequence_number: p.sequence_number, unique_id: p.unique_id })
                }
                setSub_Categories([...data])
                getImport_initial_data()
            }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    }

    const select_domain = (d) => {
        setSelected_domain(d)
    }
    const select_brand = (d) => {
        setSelected_brand(d)
    }

    const get_domain_list = async () => {
        try {
            let apires = await get_domains();
            let apires2 = await get_brand(`?perPage=${2000}`);
            let data = [];
            let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
            for (const p of sort_v) {
                data.push({ value: p._id, label: p.name })
            }
            let data2 = [];
            if (apires2.data.brands.length > 0) {
                let sort_v2 = apires2.data.brands.sort((a, b) => a.name.localeCompare(b.name));
                for (const p of sort_v2) {
                    data2.push({ value: p._id, label: p.name })
                }


            }
            console.log('get_domains:', data);
            setBrands(data2);
            setDomain_list(data)
        } catch (error) {
            console.log();
        }
    }

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let data = {
                    store_id: store_data.store._id,
                    server_token: store_data.store.server_token,
                    timezone: store_data.timezone
                }
                let apires = await get_product_group_list(data);
                if (_isMounted) {
                    console.log(16, apires);
                    if (apires.product_groups.length > 0) {
                        let data = []
                        let sort_v = apires.product_groups.sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                        for (const o of sort_v) {
                            data.push({ value: o._id, label: o.name.toString(), product_ids: o.product_ids })
                        }
                        setCategories([...data])
                        get_sub_category()
                    }
                }
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }
        initial()
        get_domain_list()
        return () => { _isMounted = false }
    }, [])

    useEffect(() => {
        console.log(52, categories);
    }, [categories])

    const [this_category, setThis_category] = useState(null);
    const filter_by_category = (d) => {
        console.log('filter_by_category', d);
        setThis_category(d)
        setSelected_subCategory([])
        let data = []
        for (const key in sub_categories) {
            const product = sub_categories[key];
            for (const i in d.product_ids) {
                const id = d.product_ids[i];
                if (product.value === id) {
                    data.push(product)
                }
            }
        }
        setFilter_subCategories(data)
    }

    useEffect(() => {
        console.log(244, this_category);
    }, [this_category])

    const filter_by_subcategory = (d) => {
        // console.log('filter_by_subcategory', d);
        setSelected_subCategory(d)
    }

    const select_this_item = (id) => {
        const index = db_selected_items.indexOf(id);
        if (index === -1) {
            setDB_selected_items([...db_selected_items, id]);
        } else {
            setDB_selected_items(db_selected_items.filter((_, i) => i !== index));
        }
    }

    const import_item_from_product = async (type) => {
        if (type !== 'all' && db_selected_items.length === 0) return;
        setLoader(true)
        setWarning({ status: false, type: '' })
        try {
            let json = {
                item_ids: type === 'all' ? ['all'] : db_selected_items,
                this_store_id: store_data.store._id,
                this_main_cate_id: this_category.value,
                this_sub_cate_id: selected_subCategory.value,
                product_sub_cate_id: selected_dbsubCategory.value,
                product_sequence_number: selected_subCategory.sequence_number.toString(),
                domain_id: selected_domain.value,
                brand_id: selected_brand ? selected_brand.value : selected_brand,
                origin: "ukbd",
                is_visible_in_store: is_visible
            }
            console.log('json for import', json);

            let apires = await import_selected_item_from_product(json)
            console.log('import_selected_item_from_product:', apires);
            if (apires.status) {
                setDB_selected_items([])
                toast.success('Items Imported successfully', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
            setIs_visible(false)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const confirmImport = (type) => {
        import_item_from_product(type)
    }

    const [import_status, setImport_status] = useState(false)
    const [importAll_status, setImportAll_status] = useState(false)

    useEffect(() => {
        let state = false
        if (selected_subCategory.value && db_selected_items.length > 0 && selected_domain) {
            if (selected_domain?.label === 'buyrealbrands.com' && !selected_brand) {
                state = false
            } else {
                state = true
            }
        } else {
            state = false
        }
        setImport_status(state)

        let state_all = false;

        if (selected_dbsubCategory.value && selected_subCategory.value && selected_domain) {

            if (selected_domain?.label === 'buyrealbrands.com' && !selected_brand) {
                state_all = false
            } else {
                state_all = true
            }

        } else {
            state_all = false
        }
        console.log(411, state_all)
        setImportAll_status(state_all)


    }, [selected_dbsubCategory, db_selected_items, selected_subCategory, selected_domain, selected_brand])

    return (
        <div className={styles.importPopup}>
            <ToastContainer />
            <div className={styles.carting_head}>
                <div className={styles.pageTitleSection}>
                    <div className={styles.pageLogoHolder} onClick={() => navigate('/')}>
                        <img className={styles.pageLogo} src={ukbd} alt="" />
                    </div>
                    <div className={styles.pageTitle} onClick={() => navigate('/')}>
                        <h2>UK & EU Brands {'>'} Partner {'>'} Import New Items</h2>
                    </div>
                </div>

                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.pageHeaderSection}>
                <div className={styles.nav} style={{ width: 'auto' }}>
                    <div className={styles.paginationSection}>
                        <Paginate
                            pageCount={totalPage}
                            onPageChange={handleChangePage}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            currentPage={currentPage}
                        />

                        <div className={styles.navItem}>
                            <span className={styles.per_page}>Per Page</span>
                            <select className={styles.btn_page} value={itemPerPage} onChange={(e) => setItemPerPage(e.target.value)}>
                                {/* <option value={10}>10</option> */}
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.filterSection}>
                <div className={styles.nav}>
                    <div className={styles.nav_item_left} style={{ width: '50%', justifyContent: 'flex-start' }}>
                        <h4 style={{ marginBottom: "0" }}>To</h4>
                        <div className={styles.nav_item_left} style={{ width: '100%', justifyContent: 'flex-start' }}>
                            <div className={styles.option_sub_group}>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Category'}
                                        value={this_category}
                                        onChange={(e) => filter_by_category(e)}
                                        options={categories}
                                    />
                                </div>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Sub-Category'}
                                        value={selected_subCategory}
                                        onChange={(e) => filter_by_subcategory(e)}
                                        options={filter_subCategories}
                                    />
                                </div>
                            </div>
                            <div className={styles.option_sub_group}>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Domain'}
                                        name="domain_id"
                                        value={selected_domain}
                                        onChange={(e) => select_domain(e)}
                                        options={domain_list}
                                    />
                                </div>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Brand'}
                                        name="brand_id"
                                        value={selected_brand}
                                        onChange={(e) => select_brand(e)}
                                        options={brand_list}
                                    />
                                </div>
                            </div>
                            <div className={styles.option_sub_group}>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <div className={styles.pop_check_section} onClick={() => setIs_visible(!is_visible)}>
                                        {
                                            is_visible ?
                                                <i style={{ fontSize: 20 }} className="fa-solid fa-circle-check"></i>
                                                :
                                                <i style={{ fontSize: 20 }} className="fa-thin fa-circle-check"></i>
                                        }
                                        <span className={styles.check_label}> Items is visible in store </span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={classNames(styles.navItem, 'select_filter')} style={{ width: 70 }}>
                                        <button className={styles.filterSelect_item} onClick={() => setWarning({ status: true, type: 'selected' })} style={import_status > 0 ? { backgroundColor: '#012965', color: '#fff' } : { pointerEvents: 'none' }} >Import</button>
                                    </div>
                                    <div className={classNames(styles.navItem, 'select_filter')} style={{ width: 70 }}>
                                        <button className={styles.filterSelect_item} onClick={() => { setWarning({ status: true, type: 'all' }) }} style={
                                            importAll_status ?
                                                { backgroundColor: '#012965', color: '#fff', width: '106px', borderTop: '1px solid rgba(255, 255, 255, 0.3)' }
                                                :
                                                { pointerEvents: 'none' }
                                        } >Import All</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.nav_item_left} style={{ width: '50%', justifyContent: 'flex-end' }}>
                        <h4 style={{ marginBottom: "0" }}>From</h4>
                        <div className={styles.nav_item_left} style={{ width: '100%', justifyContent: 'flex-end' }}>
                            <div className={styles.option_sub_group}>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Category'}
                                        value={selected_dbCategory}
                                        onChange={(e) => filter_by_dbcategory(e)}
                                        options={db_categories}
                                    />
                                </div>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Sub-Category'}
                                        value={selected_dbsubCategory}
                                        onChange={(e) => filter_by_dbsubcategory(e)}
                                        options={db_filter_sub_categories}
                                    />
                                </div>
                            </div>
                            <div className={styles.option_sub_group}>
                                <div className={classNames(styles.navItem, 'select_filter')}>
                                    <Select
                                        className={styles.filterSelect_item}
                                        placeholder={'Brands'}
                                        value={selected_dbBrand}
                                        onChange={(e) => filter_by_db_Brands(e)}
                                        options={db_filter_brands}
                                    />
                                </div>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" value={searchTerm} onChange={handleChange} onKeyUp={searchOnKeyup} className={styles.searchbox} />
                                    {is_search ?
                                        <button className={styles.searchIcon} style={{ color: '#ff0000' }} type="submit" onClick={() => resetFilter()}>
                                            <i className="fa-thin fa-circle-xmark"></i>
                                        </button>
                                        :
                                        <button className={styles.searchIcon} type="submit" onClick={searchOnClick}>
                                            <span><i className="fa-light fa-magnifying-glass"></i></span>
                                        </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.import_container}>
                <div className={styles.item_container}>
                    <div className={classNames(styles.db_item, styles.table_header)}>
                        <div className={styles.sl_section} style={{ fontWeight: 600 }}>{db_selected_items.length} / {totalItems}</div>
                        <div className={styles.img_section} style={{ fontWeight: 600 }}>Image</div>
                        <div className={styles.title_section} style={{ fontWeight: 600 }}>Item Title</div>
                        <div className={styles.exist_section} style={{ fontWeight: 600 }}>Price</div>
                        <div className={styles.exist_section} style={{ fontWeight: 600 }}></div>
                        <div className={styles.check_section} onClick={select_all_items}>
                            {
                                db_items.length > 0 && (db_items.length === db_selected_items.length) ?
                                    <i className="fa-solid fa-circle-check"></i>
                                    :
                                    <i className="fa-thin fa-circle-check"></i>
                            }
                        </div>
                    </div>
                    {loader ?
                        ''
                        :
                        db_items.length > 0 ?
                            db_items.map((v, i) => {
                                let serial = Number(i + 1).toString();
                                return (
                                    <div className={styles.db_item} key={v._id} onClick={() => select_this_item(v._id)}>
                                        <div className={styles.sl_section}>{serial}</div>
                                        <div className={styles.img_section}>
                                            <img src={PRODUCT_URL + v.image_url[0]} alt={"db_item"} />
                                        </div>
                                        <div className={styles.title_section}>{v.name}</div>
                                        <div className={styles.exist_section}> £ {v.price}</div>
                                        <div className={styles.exist_section}><span className={styles.exist_label}> {v.isExist ? 'Already exist' : 'New'}</span></div>
                                        <div className={styles.check_section}>
                                            {db_selected_items.indexOf(v._id) > -1 ?
                                                <i className="fa-solid fa-circle-check"></i>
                                                :
                                                <i className="fa-thin fa-circle-check"></i>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            )
                            :
                            <h3 style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data found...</h3>
                    }
                </div>
            </div>
            {warning.status &&
                <div className={styles.wrap_popup}>
                    <div className={styles.warning_popup}>
                        <div className={styles.popup_head}>
                            <p className={styles.pop_title}>Warning</p>
                            <span className={styles.closePopup} onClick={() => setWarning({ status: false, type: '' })}>
                                <i className="fa-thin fa-xmark"></i>
                            </span>
                        </div>
                        <div className={styles.popup_body}>
                            <div className={styles.pop_body_text}>
                                Are you sure you want to import {warning.type === 'all' ? 'all' : db_selected_items.length} items?
                            </div>
                        </div>
                        <div className={styles.popup_foot}>
                            <button className={styles.pop_cancel} onClick={() => setWarning({ status: false, type: '' })}>No</button>
                            <button className={styles.pop_submit} onClick={() => confirmImport(warning.type)}>Yes</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ImportNewItems;