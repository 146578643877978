// views/src/Payment.js
import React, { useState, useEffect } from 'react';
import styles from './Payment.module.css'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51QFEUSKzkYOXA80D0sghB6m5PGqGW0SsB10W4l1zl0aa6y2dy2d3QH190SqN6vQIW9arbjYvhfuxQUjajT1SE1VL00E0tNy9SW');

const PaymentForm = ({ setpopup, userId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [amount, setAmount] = useState(0);
    const [clientSecret, setClientSecret] = useState('');

    const createPaymentIntent = async () => {
        const response = await fetch('/api/create-payment-intent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ amount, userId }),
        });
        console.log("🚀 ~ createPaymentIntent ~ response:", response)
        const data = await response.json();
        console.log("🚀 ~ createPaymentIntent ~ data:", data)
        setClientSecret(data.clientSecret);
    };


    const handleBlur = () => {
        if (amount > 0) createPaymentIntent();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);
        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: { card: cardElement },
        });

        console.log("🚀 ~ handleSubmit ~ result:", result)

        if (result.error) {
            console.error(result.error.message);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                alert('Payment successful!');
                // Record the successful payment and activity
                await fetch('/api/payment-success', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        userId,
                        amount,
                        paymentIntentId: result.paymentIntent.id,
                        paymentMethod: 'Stripe',
                    }),
                });
            }
        }
    };


    return (
        <div className={styles.payment_form}>
            <span className={styles.close_payment} onClick={() => setpopup(false)}><i class="fa-thin fa-xmark"></i></span>
            <h2>Complete Your Payment</h2>

            <form onSubmit={handleSubmit}>
                <div className={styles.amount_input_container}>
                    <input
                        className={styles.amount_input_field}
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        onBlur={handleBlur}
                        min={0}
                        placeholder='Enter amount'
                        autoFocus
                    />
                </div>
                <div className={styles.card_element_container}>
                    <CardElement options={{ style: cardElementStyle }} />
                </div>
                <button type="submit" disabled={!stripe || amount === 0} className={styles.pay_button}>Pay ${amount}</button>
            </form>
        </div>
    );
};

const cardElementStyle = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Montserrat, sans-serif',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};


const Payment = ({ setpopup, userId }) => (
    <Elements stripe={stripePromise}>
        <PaymentForm setpopup={setpopup} userId={userId} />
    </Elements>
);

export default Payment;
